<template>
  <v-container class="ma-0 pa-0">
    <poll :pollID="pollID" :categoryID="categoryID"></poll>
  </v-container>
</template>
  <script>
import Poll from "@/components/poll/Poll.vue";

export default {
  components: {
    Poll,
  },

  computed: {
    pollID() { return parseInt(this.$route.params.pollID) },
    categoryID() { return parseInt(this.$route.params.categoryID) },
  }
}
  </script>